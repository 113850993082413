















































import { Component, Vue } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { namespace } from "vuex-class";
import TextDecimal from "@/components/TextDecimal.vue";
import { IBalanceOverview } from "@/interfaces/IBalanceOverview";
import OverviewCard from "@/views/AutomaticPaymentsOverview/OverviewCard.vue";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";

const PaymentModule = namespace("payments");

@Component({
  components: {
    Hint,
    OverviewCard,
    TextDecimal,
    FscSimpleCard,
    FscPageHeader,
  },
})
export default class AutomaticPaymentsOverview extends Vue {
  public name = "AutomaticPaymentsOverview";

  @PaymentModule.Action("balanceOverview")
  protected findBalanceOverview: any;

  @PaymentModule.Getter("getBalanceOverview")
  protected balanceOverview!: IBalanceOverview;

  public mounted(): void {
    this.findBalanceOverview();
  }
}
